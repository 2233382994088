body.login {
    background-color: #000000;
    background: linear-gradient(270deg, #000000 0%, #191919 100%);

    font-family: 'DM Sans', 'Helvetica Neue', 'Arial', sans-serif !important;
    overflow: hidden;
    color: #000000;
    @font-face {
        font-family: DM Sans;
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/app/themes/ono/resources/fonts/dm_sans_normal_400.woff2') format('woff2');
        font-stretch: normal;
        unicode-range: U+000-5FF;
    }
    @font-face {
        font-family: DM Sans;
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('/app/themes/ono/resources/fonts/dm_sans_normal_700.woff2') format('woff2');
        font-stretch: normal;
        unicode-range: U+000-5FF;
    }

    div#login {
        width: 500px;
        @media (max-width: 768px) {
            width: 90%;
            margin-top: 3rem;
        }
        h1 {
            a {
                background: url(/app/themes/ono/public/images/logo.svg) no-repeat;
                background-size: cover;
                width: 300px;
                height: 44px;
                margin: 0 auto 3rem;
            }
        }
        #nav a,
        #backtoblog a {
            color: #ffffff;
        }
    }
    .notice {
        background: #191919;
        color: #ffffff;
        font-size: 1rem;
    }
    #loginform {
        background: #18191b;
        border: 1px solid #5a6169;
        color: #ffffff;
        border-radius: 16px;
        padding: 2rem;
        label {
            font-weight: 700;
            font-size: 1rem;
        }
        input[type='text'],
        input[type='password'] {
            background: #111113;
            border: 1px solid #5a6169;
            color: #ffffff;
            padding: 0.5rem 1rem;
            font-size: 1rem;
            border-radius: 8px;
            &:focus,
            &:focus-visible {
                border: 1px solid #f76b15;
                outline: none;
                box-shadow: none;
            }
        }
        input[type='submit'] {
            background: #ffffff;
            border: 0px solid #ffffff;
            color: #000000;
            padding: 12px 1rem;
            line-height: 20px;
            font-size: 1rem;
            border-radius: 50px;
            min-width: 100px;
            margin-top: 1rem;
            font-weight: bold;
            &:hover {
                background: #eeeeee;
                color: #000000;
            }
        }
        .wp-pwd {
            button {
                color: #ffffff;
            }
        }
    }

    &:after {
        background: url(/app/themes/ono/public/images/cover.png) no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        content: '';
        position: absolute;

        z-index: -1;
        @media (max-width: 768px) {
            display: none;
        }
    }
}
