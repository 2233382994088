body.wp-admin {
    @media (min-width: 600px) {
        .block-editor-url-input input[type='text'] {
            width: 100%;
        }
    }

    .acp-sh-container.-sticky {
        top: 40px;
    }

    /* Fix base preflight resets on WordPress Admin elements */
    button.components-button.is-primary {
        background-color: var(--wp-admin-theme-color);
    }
    /* End */

    .block-editor-block-list__block.wp-block-acf-square-container {
        overflow: hidden;
    }

    .edit-post-visual-editor__content-area {
        padding-bottom: 200px !important;
    }

    .post-type-iot-region .wp-block-post-content,
    .post-type-iot-use-case .wp-block-post-content {
        &::after {
            @apply bg-white opacity-50 absolute top-0 left-0 w-full h-full z-[1000] content-[''];
        }
        &::before {
            @apply text-black absolute top-0 left-0 mt-5 w-full text-center z-[1001] whitespace-pre-wrap;
            content: 'This content is generated from the ACF fields. The content is not editable from the Gutenberg editor. \A Scroll down to edit fields or do it from the overview page.';
        }
    }

    body .editor-styles-wrapper {
        margin-top: 0px !important;
    }

    .swiper-wrapper:empty {
        min-height: 300px;
    }

    .components-button:focus:not(:disabled) {
        box-shadow: none;
        outline: 0px solid #0000;
    }

    /* body.post-type-post .editor-styles-wrapper {
    @apply text-black bg-white;
} */

    .wp-block.is-selected {
        outline: 1px solid var(--wp--preset--color--white);
    }

    ol .block-editor-rich-text__editable.rich-text {
        @apply inline;
    }

    .block-editor-block-list__block-html-textarea {
        @apply text-black !important;
    }

    .interface-complementary-area h2,
    .components-panel__body-toggle.components-button {
        font-weight: bold;
        font-size: 14px;
    }

    /* .components-panel__body {
        .components-base-control:first-of-type {
            margin-top: 16px;
        }
    } */

    .components-panel__body-title {
        background: #eee;
    }

    .components-grid.components-tools-panel {
        position: relative;
        &:after {
            width: 100%;
            height: 49px;
            background: #eee;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
        /* .components-heading {
        margin-bottom: 16px;
    } */
        > div:not(.components-tools-panel-header) {
            margin-top: 16px;
        }
    }

    .acf-table {
        border: #ccd0d4 solid 1px !important;
        background: #fff !important;
        border-spacing: 0 !important;
        border-radius: 0 !important;
        table-layout: auto !important;
        padding: 0 !important;
        margin: 0 !important;
        width: 100% !important;
        clear: both !important;
        box-sizing: content-box !important;
    }

    /* Block Title */
    .block-editor-block-title {
        position: absolute;
        top: -39px;
        left: 5px;
        line-height: 1;
        display: inline-flex;
        height: auto;
        align-items: center;
        font-weight: bold;
        padding: 0.5rem 1rem;
        margin: 0 -6px;
        border: 1px solid #1e1e1e;
        border-radius: 2px;
        background-color: #fff;
        min-width: 300px;
    }

    /* Gutenberg Editor title field */
    .edit-post-visual-editor__post-title-wrapper {
        margin: 0 !important;
        padding: 3rem 3rem 2rem;
        background: #eee;
        h1 {
            position: relative;
            font-size: 2rem !important;
            margin-bottom: 0 !important;
            line-height: 2rem !important;
            @apply text-black;
            &::after {
                position: absolute;
                top: -20px;
                left: 0px;
                content: 'Page Title';
                font-size: 1rem;
                line-height: 1rem;
                font-weight: normal;
                opacity: 0.5;
            }
        }
    }
    /* End */

    .block-editor-block-list__layout.is-root-container > :where(:not(.alignleft):not(.alignright):not(.alignfull):not(.wp-block-columns):not(.wp-block-group)) {
        max-width: 100% !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .editor-styles-wrapper .is-layout-constrained > :where(:not(.alignleft):not(.alignright):not(.alignfull):not(.wp-block-columns):not(.wp-block-group)) {
        max-width: 100% !important;
        margin-left: auto !important;
        margin-right: auto !important;
        padding: 0 0rem;
    }

    .editor-styles-wrapper .wp-block-columns .wp-block-column {
        @apply min-w-[50px];
    }

    .is-editorskit-heading-label-on:not(.is-iceberg) .editor-styles-wrapper .wp-block[data-type='core/heading']:not(.is-selected):after {
    }

    .interface-interface-skeleton__content {
        padding-bottom: 40px;
    }

    @media (min-width: 782px) {
        .interface-complementary-area {
            width: 400px !important;
        }
    }

    .interface-complementary-area__fill {
        width: 400px !important;
    }

    .wp-picker-clear {
        display: none !important;
    }

    .wp-picker-container {
        display: block;
        input[type='text'].wp-color-picker {
            outline: 0 !important;
            border: 1px solid #dddddd !important;
            box-shadow: none !important;
            pointer-events: none;
        }
        &::active {
            display: block;
        }
    }

    .iris-picker.iris-border {
        width: 100% !important;
        height: auto !important;
        padding-bottom: 0 !important;
        border: 0;
    }

    .iris-border .iris-picker-inner {
        display: none;
    }

    .iris-palette-container {
        position: static !important;
        font-size: 0;
        line-height: 1;
        padding: 3px;
    }

    .iris-palette {
        border: 3px solid white;
        border-radius: 5px !important;
        box-shadow: inset 0 0 1px black !important;
        display: inline-block;
        float: none !important;
        height: 36.9px !important;
        margin: 0 !important;
        margin-left: 0 !important;
        width: 36.9px !important;
    }

    .wp-picker-clear {
        display: inline-block !important;
    }

    .block-editor .wp-block-column:not(.border) {
        outline: 1px dashed rgba(0, 0, 0, 0.2);
    }

    .is-layout-flow.block-editor-block-list__block:not(.is-selected) > .block-list-appender:only-child:after {
        border-color: rgba(255, 255, 255, 0.5);
    }

    .wp-block-heading,
    .wp-block-paragraph,
    .wp-block-image,
    .wp-block-column,
    .wp-block-acf-button {
        &:hover {
            outline: 1px dashed #fff;
        }
    }

    body .editor-styles-wrapper .lg\:hidden {
        display: block !important;
        opacity: 0.4;
    }

    div[class*='arrow-'] {
        label:before {
            font-family: 'dashicons';
            color: #1e1e1e;
            display: inline-block;
            -webkit-font-smoothing: antialiased;
            font-size: 18px;
            vertical-align: middle;
            margin-right: 4px;
            margin-left: 0px;
        }
    }

    .layout[data-layout='5_column'] {
        .acf-table {
            .acf-row {
                overflow: scroll;
            }
        }
    }

    .arrow-top {
        label:before {
            content: '\f343';
        }
    }

    .arrow-right {
        label:before {
            content: '\f341';
        }
    }

    .arrow-bottom {
        label:before {
            content: '\f347';
        }
    }

    .arrow-left {
        label:before {
            content: '\f345';
        }
    }

    /* Format SVG feather icons */

    .feather-icons-popover {
        .components-popover__content {
            padding: 1rem;
            .feather-icons-icons-panel {
                width: 400px;
                ul {
                    list-style: none;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    li {
                        button {
                            svg {
                                fill: none !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .custom-url-popover {
        width: 400px;
        .components-popover__content {
            padding: 10px;
            width: 100%;
        }
    }

    .custom-modal-control {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .preview-button-area {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            padding: 4rem 0;
            background: #6f6d6d;
        }
    }

    .custom-radio-control .components-base-control__field .components-flex {
        flex-direction: row;
        gap: 0;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .custom-radio-control .components-radio-control__option {
        margin-right: 1em; /* Adjust spacing between radio buttons */
    }

    .select2-component {
        input[type='text']:focus {
            box-shadow: none !important;
        }
    }

    .wp-block-heading {
        svg {
            width: 100px;
            height: 100px;
            background-color: rgba(0, 0, 0, 0.1);
            position: relative;
            display: inline-block;
        }
    }

    /* Remove adds from Replace Media plugin */
    .upsell-wrapper,
    .shortpixel-notice,
    .compat-field-emr-remove-background {
        display: none !important;
    }

    .components-popover.block-editor-block-popover {
        z-index: 100 !important;
    }

    .components-dropdown__content .components-popover__content {
        min-width: 310px;
    }

    form.slim label[id^='label-phone-'] {
        display: none !important;
    }
}
