/* Fixed issue with block jumping because of empty <style> tag being injected */
/* .editor-styles-wrapper .is-layout-flow > style:first-child + * {
    margin-block-start: 0px !important;
} */

.acf-postbox {
    .hndle {
        font-size: 13px !important;
        padding-left: 16px !important;
    }
}

.acf-button-group {
    font-size: 12px;
    label {
        padding: 4px 6px;
        cursor: pointer;
    }
}

.block-editor-block-parent-selector__button {
    background-color: #000 !important;
    color: #fff !important;
}

.block-editor .edit-post-sidebar .acf-fields > .acf-field > .acf-label label {
    font-weight: bold !important;
}

.acf-field-group > .acf-label label {
    font-size: 18px;
    margin-bottom: 10px;
}

.acf-field-editor-palette .acf-input {
    max-width: 100% !important;
}

/* .acf-field-editor-palette .acf-input ul {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
} */

/* ACF side editor styles */
body .block-editor .edit-post-sidebar .acf-fields > .acf-field {
    margin: 0px;
    padding: 15px 12px;
}
body .block-editor .edit-post-sidebar .acf-fields > .acf-field[data-width='66'] {
    width: 66% !important;
    float: left !important;
}
body .block-editor .edit-post-sidebar .acf-fields > .acf-field[data-width='50'] {
    width: 50% !important;
    float: left !important;
}
body .block-editor .edit-post-sidebar .acf-fields > .acf-field[data-width='33'] {
    width: 33% !important;
    float: left !important;
}

/* div[class*='acf-field-data-table'].acf-field-group {
    & > .acf-label {
        position: relative;
        &::after {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-up'%3E%3Cpolyline points='18 15 12 9 6 15'%3E%3C/polyline%3E%3C/svg%3E");
            background-size: 16px 16px;
            height: 16px;
            width: 16px;
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translate(0, -50%);
        }
    }
    &.group-collapsed {
        padding: ;
        & > .acf-label {
            margin-bottom: 0 !important;
            label {
                margin-bottom: 0;
            }
            &::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
            }
        }
    }
} */

/* Make sure acf block dont render spacing twice */
div[class*='wp-block-acf']:not(.wp-block-acf-square-container) {
    padding: 0 !important;
}

.acf-editor-wrap iframe {
    height: 300px !important;
    min-height: 300px;
}

.acf-flexible-content .layout {
    font-size: 12px;
    .acf-field-group > .acf-label > label {
        font-size: 14px !important;
    }
}

.acf-actions {
    margin-top: 16px;
    .acf-button {
        color: #fff !important;
    }
}

.acf-field-editor-palette .acf-input ul {
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 12px !important;
    position: relative !important;
    z-index: 1 !important;
    .components-circular-option-picker__option-wrapper {
        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

/* Editor fix for row columns in the layout */
#acf-group_comparison_table {
    .acf-field-cp-cols {
        > .acf-input {
            > .acf-fields {
                display: flex !important;
                flex-direction: row !important;
                border: 0 !important;
                overflow-x: scroll !important;
                > .acf-field {
                    flex: 1 1 0px !important;
                    border: 0 !important;
                    min-width: 400px !important;

                    > .acf-field {
                        flex: 1 1 0px !important;
                        border: 0 !important;
                        padding: 0 !important;
                    }
                }
            }
        }
    }
    div[data-name='columns_1_column_group'],
    div[data-name='columns_2_column_group'],
    div[data-name='columns_3_column_group'],
    div[data-name='columns_4_column_group'],
    div[data-name='columns_5_column_group'] {
        > .acf-input {
            > .acf-fields {
                display: flex !important;
                flex-direction: row !important;
                border: 0 !important;
                > .acf-field {
                    flex: 1 1 0px !important;
                    border: 0 !important;
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }
            }
        }
        > .acf-label {
            display: none;
        }
    }

    .components-circular-option-picker {
        min-width: 100px;
    }
    .acf-switch {
        /* background: #dfd6d6; */
    }
}
