.wp-block-acf-accordion-item {
    &.is-selected,
    &.has-child-selected {
        .content {
            display: block !important;
        }
    }
}

.wp-block-acf-slider {
    .block-editor-block-list__layout {
        .wp-block-acf-slider-item {
            &:not(:first-of-type) {
                position: absolute;
                opacity: 0 !important;
            }
        }
    }

    &.is-selected,
    &.has-child-selected {
        .block-editor-block-list__layout {
            .wp-block-acf-slider-item {
                opacity: 1 !important;
                position: relative;
            }
        }
    }
}

.wp-block-acf-tabs {
    .block-editor-block-list__layout {
        .wp-block-acf-tabs-item {
            &:not(:first-of-type) {
                position: absolute;
                opacity: 0 !important;
                top: 0;
            }
        }
    }

    &.is-selected,
    &.has-child-selected {
        .block-editor-block-list__layout {
            .wp-block-acf-tabs-item {
                opacity: 1 !important;
                position: relative;
            }
        }
    }
}
