.menu-item-settings .description-wide.field-description {
    display: block;
}
body.ac-page .wrap table,
.wrap table {
    td.rank_math_seo_details.column-rank_math_seo_details {
        @apply grid gap-x-4 w-[500px] grid-rows-2 !important;
        grid-template-columns: 100px 1fr 1fr;
        .seo-score {
            @apply row-span-2;
        }
        /* .rank-math-link-count {
            @apply col-start-2;
        } */
        .rank-math-column-display:last-of-type {
            @apply mb-0;
        }
        .rank-math-column-display {
            @apply mb-0;
        }
    }
    th.column-rank_math_seo_details {
        @apply w-[500px] !important;
        &:not(.bulk-editing) > span:first-of-type {
            @apply flex w-full;
        }
    }
}
.widefat td,
.widefat td p,
.widefat td ol,
.widefat td ul {
    font-size: 12px;
}

#the-list {
    tr.level-1 {
        .check-column {
            position: relative;
            padding-left: 10px;
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0px;
                width: 5px;
                height: 100%;
                background-color: #9ec2e6;
            }
        }
    }
    tr.level-2 {
        .check-column {
            position: relative;
            padding-left: 10px;
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0px;
                width: 5px;
                height: 100%;
                background-color: #4f94d4;
            }
        }
    }
}

#wp-admin-bar-logo {
    a {
        font-size: 15px;
    }
}

@media (min-width: 786px) {
    html.wp-toolbar {
        padding-top: 40px;
    }
}

body.admin-bar {
    #wp-admin-bar-logo {
        img {
            padding-top: 12px;
        }
    }
}

body.wp-admin,
body.admin-bar {
    .mobile-menu {
        @apply top-[46px] pb-[46px] !important;
    }
    #wpadminbar {
        @apply h-[46px] md:h-[40px] !important;
        @media (max-width: 786px) {
            #wp-admin-bar-top-secondary li,
            #wp-admin-bar-root-default li {
                @apply h-[46px] !important;
                .ab-item {
                    @apply h-[46px] !important;
                }
            }
        }
        .quicklinks {
            a,
            .ab-empty-item,
            .ab-item {
                height: 40px;
                line-height: 40px;
                &:before {
                    top: 5px !important;
                }
            }
            .ab-icon {
                padding-top: 8px !important;
            }
            @media (max-width: 786px) {
                .ab-icon {
                    padding-top: 0px !important;
                    @apply h-[40px] w-[52px] !important;
                    font-size: 32px !important;
                    &:before {
                        top: 4px !important;
                    }
                }
                .ab-item:before {
                    font-size: 32px !important;
                }
            }
        }
    }
    #adminmenu {
        margin: 8px 0 !important;
        .wp-menu-separator {
            /* margin-bottom: 20px !important; */
            div.separator {
                height: 2px;
                padding: 0;
                background-color: #474747;
                margin-top: 8px;
            }
        }
        .wp-submenu {
            left: 200px;
        }
        .wp-has-current-submenu .wp-submenu {
            left: auto;
        }
        #wpadminbar #adminbarsearch:before {
            top: 9px;
        }
    }
    #adminmenuback,
    #adminmenuwrap,
    #adminmenu,
    #adminmenu .wp-submenu {
        width: 200px;
    }
    @media (min-width: 786px) {
        #wpcontent,
        #wpfooter {
            margin-left: 200px;
        }
    }
}

@media (min-width: 961px) {
    .auto-fold .interface-interface-skeleton,
    .auto-fold .edit-post-layout .components-editor-notices__snackbar {
        left: 200px;
    }
}

@media (min-width: 783px) {
    .interface-interface-skeleton {
        top: 40px;
    }
}

body.wp-admin {
    #wp-link .query-results {
        top: 0 !important;
        position: relative !important;
    }

    /* Fix for Rank Math button until its fixed by Rank Math */
    .interface-pinned-items {
        button[aria-label='Rank Math'] {
            min-width: 100px !important;
        }
    }

    #wpadminbar #wp-admin-bar-switch-sites > .ab-item:before {
        content: '\f541';
        top: 2px;
    }

    #wp-link-wrap {
        height: 800px;
        margin-top: -400px;
        width: 700px;
        margin-left: -350px;
    }

    #rank_math_review_plugin_notice,
    #new_post_type {
        display: none;
    }

    #adminmenu li.wp-menu-separator {
        height: 38px;
    }

    #menu-posts {
        position: relative;
        &::before {
            content: 'Content';
            position: absolute;
            top: -26px;
            left: 10px;
            text-transform: uppercase;
            color: rgba(240, 246, 252, 0.7);
            font-size: 14px;
        }
    }

    #menu-appearance {
        position: relative;
        &::before {
            content: 'Settings';
            position: absolute;
            top: -26px;
            left: 10px;
            text-transform: uppercase;
            color: rgba(240, 246, 252, 0.7);
            font-size: 14px;
        }
    }

    #menu-posts-company-logo {
        position: relative;
        &::before {
            content: 'Collections';
            position: absolute;
            top: -26px;
            left: 10px;
            text-transform: uppercase;
            color: rgba(240, 246, 252, 0.7);
            font-size: 14px;
        }
    }

    .attachments-wrapper {
        /* prettier-ignore */
        div[class*="subtype-svg"] {
        background-color: #1d1d1d;
    }
        /* .type-image {
        background-color: #000000;
    } */
    }

    .ab-top-menu {
        &:not(.ab-top-secondary) li {
            border-right: 1px #474747 solid;
            &:last-of-type {
                border-right: none;
            }
        }
        &.ab-top-secondary {
            li {
                border-left: 1px #474747 solid;
                &:last-of-type {
                    border-left: none;
                }
            }
        }
        li {
            strong {
                @apply font-bold !important;
            }
        }
    }

    .button-danger {
        background-color: #dc3545 !important;
        border-color: #dc3545 !important;
        color: #fff !important;
        &:hover {
            background-color: #c82333 !important;
            border-color: #bd2130 !important;
        }
    }

    .button-success {
        background-color: #28a745 !important;
        border-color: #28a745 !important;
        color: #fff !important;
        &:hover {
            background-color: #218838 !important;
            border-color: #1e7e34 !important;
        }
    }
}

.acp-scrolling-indicator {
    height: 35px;
}

.type-iot-region,
.type-iot-use-case {
    @apply relative !important;
    .column-title,
    .check-column {
        @apply sticky top-0 left-0 z-20 !important;
    }
    .column-title {
        @apply left-[27px] z-10 !important;
    }
}
.acp-hts-wrapper.-less {
    th.check-column,
    td.column-title {
        @apply bg-white !important;
    }
    td.column-title {
        &:after {
            @apply content-[''] absolute top-0 right-0 h-full w-[1px] bg-gray-300 !important;
        }
    }
    .striped > tbody > :nth-child(odd) {
        th.check-column,
        td.column-title {
            background-color: #f6f7f7 !important;
        }
    }
}
